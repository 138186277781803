
























































































import Vue from 'vue';
import router from '@/router';
import Component from 'vue-class-component';

@Component
class LoginComponent extends Vue {
    public pwShow: boolean = false;
    public showLogin: boolean = true;
    public email: string = '';
    public password: string = '';
    public valid: boolean = true;
    public message: string = '';
    public processing: boolean = false;
    public messageColor: string = '';
    public successMessage: string;
    public errorMessageColor: string;
    public successMessageColor: string;
    public serverErrorMessage: string;
    public forgotPassMessage: string = '';
    public loginMessage: string = '';

    public rules = {
        required: (value: string) => !!value || 'required field',
        email: (value: string) => {
            const pattern = new RegExp(''
                + /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@/.source
                + /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source,
            );
            return pattern.test(value) || 'Invalid e-mail.';
        },

    };

    public constructor() {
        super();
        this.successMessage = 'Reset link was sent to your email address';
        this.errorMessageColor = 'red';
        this.successMessageColor = 'green';
        this.serverErrorMessage = 'server error';
    }

    public mounted() {
        (this.$refs.loginForm as any).$el.focus();
    }

    public login(): void {
        if ((this.$refs.loginForm as any).validate()) {
            this.processing = true;
            this.$store.dispatch('authState/login', {user: this.email, pass: this.password})
                .then(() => {
                    this.processing = false;

                    this.$store.dispatch('profile/getProfile').finally(() => {
                        router.push('licensing-report');
                    });
                })
                .catch(error => {
                    this.processing = false;
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.loginMessage = 'Invalid email or password';
                            this.messageColor = this.errorMessageColor;
                        }
                    } else if (error.request) {
                        this.loginMessage = 'Enable to login';
                        this.messageColor = this.errorMessageColor;
                    } else {
                        this.loginMessage = 'Unknown error';
                        this.messageColor = this.errorMessageColor;
                    }
                });
        }
    }

    public sendResetLink(): void {
        if ((this.$refs.forgotPasswordForm as any).validate()) {
            this.processing = true;
            this.$store.dispatch('authState/sendResetLink', {email: this.email})
                .then(response => {
                    this.processing = false;
                    if (response.data.success) {
                        this.forgotPassMessage = this.successMessage;
                        this.messageColor = this.successMessageColor;
                    } else {
                        this.forgotPassMessage = response.data.error;
                        this.messageColor = this.errorMessageColor;
                    }
                })
                .catch(() => {
                    this.processing = false;
                    this.forgotPassMessage = this.serverErrorMessage;
                    this.messageColor = this.errorMessageColor;
                });
        }
    }


}

export default LoginComponent;
